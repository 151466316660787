<template>
  <!--begin::Card-->
  <!--  <div class="card">-->
  <!--    <div class="card-body">-->
  <!--      <RemoteTable :columns="table.columns"></RemoteTable>-->
  <!--    </div>-->
  <!--  </div>-->
  <div class="card">
    <!--begin::Card Body-->
    <div class="card-body">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column class="p-0 m-0" prop="" label="Cover" min-width="8">
          {{ `<img
            width="40"
            height="40"
            src="https://widgetwhats.com/app/uploads/2019/11/free-profile-photo-whatsapp-4.png"
            alt=""
          />`}}
        </el-table-column>
        <el-table-column prop="name" label="Name" min-width="14">
        </el-table-column>
        <el-table-column prop="comment" label="Comment" min-width="35">
        </el-table-column>
        <el-table-column prop="rate" label="Rate" min-width="7">
        </el-table-column>
        <el-table-column prop="order" label="Ordering" min-width="9">
        </el-table-column>
        <el-table-column prop="review_date" label="Date" min-width="15">
        </el-table-column>
        <el-table-column label="Actions" min-width="10"></el-table-column>
      </el-table>
      <div class="d-flex justify-content-end mt-3">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          v-model:currentPage="currentPage4"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="100"
          layout="total, sizes, prev, pager, next"
          :total="400"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted } from "vue";
import { setCurrentPageTitle } from "../core/helpers/breadcrumb";
import RemoteTable from "../components/RemoteTable.vue";

export default {
  name: "Reviews",
  // components: { RemoteTable },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Clients Reviews");
    });
  },
  data: function () {
    return {
      table: {
        columns: [
          {
            data: "aaa",
            label: "Test",
            width: "10",
          },
        ],
      },
      tableData: [
        {
          name: "Ahmed Ahmed",
          comment:
            "لقد أعجبني هذا التطبيق كثيرا جدا، لقد أعجبني هذا التطبيق كثيرا جدا، لقد أعجبني هذا التطبيق كثيرا جدا، لقد أعجبني هذا التطبيق كثيرا جدا، لقد أعجبني هذا التطبيق كثيرا جدا، ",

          review_date: "2016-05-03",
          rate: 5,
          order: 1,
        },
      ],
      currentPage4: 4,
    };
  },
  methods: {
    handleSizeChange(val) {
      console.log(`${val} items per page`);
    },
    handleCurrentChange(val) {
      console.log(`current page: ${val}`);
    },
  },
};
</script>

<style scoped></style>
